import { useEffect, useRef, useState } from "react";
import {
  GetDeceasedPersonDetailsResponse,
  InheritorWithoutSsn,
} from "@trustribbon/ribbon-ec-types";
import { DeceasedPersonWithCaseId, useInheritorStore } from "@store/inheritor.store";
import { useInheritorDetails } from "@hooks/useInheritorDetails.hook";
import { DeceasedCard } from "./components/DeceasedCard/DeceasedCard.component";
import {
  InheritorCard,
  InheritorCardRef,
} from "./components/InheritorCard/InheritorCard.component";
import {
  DeceasedAccountsCard,
  FinancialAccountWithAllocationAndSelect,
} from "./components/DeceasedAccountsCard/DeceasedAccounts.component";
import { OtherInheritors } from "./components/OtherInheritors/OtherInheritors.component";
import { DeceasedCardLoading } from "./components/CardLoading/DeceasedCardLoading.component";
import { useInheritanceAccountsWithAllocation } from "@src/hooks/useInheritanceAccountsWithAllocation.hook";
import { RequestMissingDocumentsModal } from "./components/RequestMissingDocumentsModal/RequestMissingDocumentsModal.component";
import { Heading, HStack, Stack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { CardWrapper } from "@src/components/Layout/Card/Card.component";
import { useInstitutionStore } from "@src/store/institution.store";

export function InheritorOverview() {
  const { isOpen: isModalOpen, onClose, onOpen: onOpenModal } = useDisclosure();
  const inheritorCardRef = useRef<InheritorCardRef>(null);

  const { currentInheritor, setCurrentInheritor, setCurrentDeceased } = useInheritorStore();
  const {
    inheritorWithDetails,
    isLoading: inheritorIsLoading,
    mutate: inheritorDetailsMutate,
  } = useInheritorDetails(currentInheritor?.id ?? "");
  const {
    inheritanceAccountsWithAllocation,
    isLoading: accountsAreLoading,
    mutate: accountsMutate,
  } = useInheritanceAccountsWithAllocation(inheritorWithDetails?.deceasedPersons.at(0)?.caseId);
  const { currentInstitution } = useInstitutionStore();
  const [shouldDisplayLiabilities, setShouldDisplayLiabilities] = useState(false);

  useEffect(() => {
    setCurrentInheritor(inheritorWithDetails as unknown as InheritorWithoutSsn);

    setCurrentDeceased(
      inheritorWithDetails?.deceasedPersons[0] as unknown as DeceasedPersonWithCaseId
    );

    if (
      currentInheritor?.firstName === "Philip" &&
      currentInheritor?.lastName === "Kowalski" &&
      currentInstitution?.subdomain === "arlingtoncu"
    ) {
      setShouldDisplayLiabilities(true);
    }
  }, [
    inheritorWithDetails,
    inheritorIsLoading,
    setCurrentDeceased,
    setCurrentInheritor,
    currentInstitution,
    currentInheritor?.firstName,
    currentInheritor?.lastName,
  ]);

  function onCloseModal() {
    onClose();
    if (inheritorCardRef && inheritorCardRef.current) {
      inheritorCardRef.current.cancelEditing();
    }
  }

  async function onSaveModal() {
    if (inheritorCardRef && inheritorCardRef.current) {
      onClose();
      await inheritorCardRef.current.submitForm();
    }
  }

  return (
    <>
      <InheritorCard
        ref={inheritorCardRef}
        inheritorWithDetails={inheritorWithDetails}
        isLoading={inheritorIsLoading}
        mutate={inheritorDetailsMutate}
        openMissingDocumentsModal={onOpenModal}
      />
      {inheritorIsLoading ? (
        <DeceasedCardLoading headingLabel="Deceased" withEditButton />
      ) : (
        inheritorWithDetails?.deceasedPersons?.map(deceasedPerson => {
          const deceasedDetails = {
            id: deceasedPerson.id,
            firstName: deceasedPerson.firstName,
            middleName: deceasedPerson?.middleName,
            lastName: deceasedPerson?.lastName,
            totalAssets: inheritorWithDetails?.totalAssets,
            latestDeathCertificate: deceasedPerson.latestDeathCertificate,
            dateOfBirth: deceasedPerson.dateOfBirth,
          };

          return (
            <DeceasedCard
              key={deceasedPerson.id}
              deceasedDetails={deceasedDetails as unknown as GetDeceasedPersonDetailsResponse}
              isLoading={inheritorIsLoading}
              mutate={inheritorDetailsMutate}
            />
          );
        })
      )}
      {shouldDisplayLiabilities && <LiabilitiesCard />}
      {!shouldDisplayLiabilities && (
        <DeceasedAccountsCard
          financialAccounts={
            inheritanceAccountsWithAllocation as FinancialAccountWithAllocationAndSelect[]
          }
          isLoading={accountsAreLoading}
          mutate={accountsMutate}
        />
      )}
      <OtherInheritors
        isLoading={inheritorIsLoading}
        otherInheritors={inheritorWithDetails?.deceasedPersons.at(0)?.otherInheritors}
      />

      <RequestMissingDocumentsModal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onSave={onSaveModal}
      />
    </>
  );
}

function LiabilitiesCard() {
  const tableHeaders = [
    { title: "ACCOUNT NUMBER", shouldDisplay: true },
    { title: "ACCOUNT TYPE", shouldDisplay: true },
    { title: "AMOUNT", shouldDisplay: true },
    { title: "INHERITOR'S ALLOCATION", shouldDisplay: true },
    { title: "LAST PAYMENT RECEIVED", shouldDisplay: true },
  ];

  const liabilities = [
    {
      id: "1392223323244",
      type: "Auto Loan",
      amount: "$10,100",
      allocation: "100%",
      lastPaymentReceived: "09/24/2024",
    },
  ];
  return (
    <VStack w="full" gap={3} mb={9}>
      <HStack w="full" justifyContent="space-between" flexWrap="wrap">
        <Heading variant="h1" fontSize="24px" color="gray.700" lineHeight="36px" fontWeight={600}>
          Liabilities
        </Heading>
      </HStack>
      <CardWrapper>
        <Stack
          w="full"
          justifyContent="space-between"
          gap={8}
          padding="14px 24px 0"
          flexDirection="row"
        >
          {tableHeaders.map(
            header =>
              header.shouldDisplay && (
                <VStack
                  key={header.title}
                  alignItems="flex-start"
                  gap={2}
                  justifyContent="space-evenly"
                  width={1 / tableHeaders.length}
                >
                  <Text fontSize={12} color="#718096" fontWeight={700} lineHeight="16px">
                    {header.title}
                  </Text>
                </VStack>
              )
          )}
        </Stack>

        {liabilities.map((liability, idx, liabilitiesArray) => {
          const isLastIndex = idx === liabilitiesArray.length - 1;
          return (
            <Stack
              key={liability.id}
              gap={8}
              w="full"
              justifyContent="space-between"
              flexDirection="row"
              padding={`8px 24px ${isLastIndex ? "14px" : "4px"}`}
            >
              <Text width={1 / tableHeaders.length}>{liability.id}</Text>
              <Text width={1 / tableHeaders.length}>{liability.type}</Text>
              <Text width={1 / tableHeaders.length} fontWeight={700}>
                {liability.amount}
              </Text>
              <Text width={1 / tableHeaders.length} fontWeight={700}>
                {liability.allocation}
              </Text>
              <Text width={1 / tableHeaders.length}>{liability.lastPaymentReceived}</Text>
            </Stack>
          );
        })}
      </CardWrapper>
    </VStack>
  );
}
